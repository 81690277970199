import MuiSlider, {
  type SliderProps as MuiSliderProps,
} from '@mui/material/Slider';
import { styles } from './utils';

export interface SliderProps
  extends Omit<MuiSliderProps, 'sx' | 'style' | 'onChange'> {
  className?: string;
  style?: MuiSliderProps['sx'];
  onChange: (value: number) => void;
}

export default function Slider({
  className,
  style = {},
  marks = true,
  defaultValue = 1,
  min = 1,
  step = 1,
  onChange,
  ...props
}: SliderProps) {
  const handleChange: MuiSliderProps['onChange'] = (e, i) => {
    // It'll never be an array, but MuiSlider types it as number[] | number
    return onChange(Array.isArray(i) ? i[0] : i);
  };

  return (
    <MuiSlider
      marks={marks}
      className={className}
      sx={{ ...styles.slider, ...style }}
      valueLabelDisplay="auto"
      defaultValue={defaultValue}
      min={min}
      step={step}
      onChange={handleChange}
      {...props}
    />
  );
}
