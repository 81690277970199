import { useRef } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import CheckBox from '@/components/CheckBox';
import TruncatedText from '@/components/TruncatedText';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import OptionsIcon from '@/icons/OptionsIcon';
import FileInfoDialog from '@/dialogs/FileInfoDialog';
import DeleteFileDialog from '@/dialogs/DeleteFileDialog';
import { useFile } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { classes, styles } from './utils';

export type FilesListItemProps = {
  editable?: boolean;
  isOpen: boolean;
  fileID: number;
  onToggle: (fileID: number) => void;
  onDelete: (fileID: number) => void;
};

export default function FilesListItem({
  editable,
  isOpen,
  fileID,
  onToggle,
  onDelete,
}: FilesListItemProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isEditDialogOpen, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle(false);
  const file = useFile(fileID);

  const handleEdit = () => {
    toggleEditDialog();
    toggleMenu(false);
  };

  const handleDelete = () => {
    toggleDeleteDialog();
    toggleMenu(false);
  };

  return (
    <>
      <ListItem className={classes.wrapper}>
        <CheckBox
          isChecked={isOpen}
          className={classes.checkbox}
          name="checkbox"
          onChange={() => onToggle(file.id)}
        />
        <Box className={classes.itemWrapper} sx={styles.itemWrapper}>
          <div className={classes.content}>
            <TruncatedText className={classes.label}>{file.name}</TruncatedText>
          </div>
          {editable && (
            <OptionsIcon
              ref={menuToggleRef}
              className={classes.optionsIcon}
              onClick={() => toggleMenu()}
            />
          )}
          {isMenuOpen && (
            <DefaultOptionsMenu
              open={isMenuOpen}
              anchorTo={menuToggleRef.current}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onClose={() => toggleMenu(false)}
            />
          )}
        </Box>
      </ListItem>
      {isEditDialogOpen && (
        <FileInfoDialog fileID={fileID} onClose={() => toggleEditDialog()} />
      )}
      {isDeleteDialogOpen && (
        <DeleteFileDialog
          fileID={fileID}
          onDelete={onDelete}
          onClose={() => toggleDeleteDialog()}
        />
      )}
    </>
  );
}
