import type { HTMLAttributes } from 'react';

// https://stackoverflow.com/a/52173021/5839360: TypeScript has a restriction that an interface can only extend a dotted name, so you have to define a type alias
type DivProps = HTMLAttributes<HTMLDivElement>;
export interface TabProps extends DivProps {
  name: string;
  label: string;
  disabled?: boolean;
}

export default function Tab({ name, label, disabled, ...props }: TabProps) {
  return <div {...props} />;
}
