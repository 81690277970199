import PauseIcon from '@/icons/PauseIcon';
import PlayIcon from '@/icons/PlayIcon';
import IconButton from '@/components/IconButton';

export type PlayButtonProps = {
  isPlaying: boolean;
  onChange: () => void;
};

export default function PlayButton({ isPlaying, onChange }: PlayButtonProps) {
  return (
    <IconButton
      title={isPlaying ? 'Pause' : 'Play'}
      titlePosition="top"
      onClick={onChange}>
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </IconButton>
  );
}
