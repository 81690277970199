import { theme } from '@/utils/theme';

export const classes = {
  summary: 'px-4',
  summaryText: 'font-semibold text-base text-grey-8',
  expandIcon: 'text-3xl text-grey-6',
};

export const styles = {
  accordion: {
    '&:before': { display: 'none' },
    '& .MuiAccordionSummary-root': {
      [theme.breakpoints.down('md')]: {
        minHeight: 0,
      },
    },
    '& .MuiAccordionSummary-content': {
      [theme.breakpoints.down('md')]: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    '& .MuiAccordionDetails-root': { padding: 0 },
  },
};
