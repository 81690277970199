export const SPEEDS = [0.5, 1, 2] as const;

export type Speed = (typeof SPEEDS)[number];

export const classes = {
  button:
    'w-18 flex items-center justify-center px-3 py-2 rounded hover:bg-grey-3 transition-colors',
  label: 'text-base text-grey-7 cursor-pointer',
  arrow: 'text-grey-6 arrow',
};

export const styles = {
  button: {
    '& .arrow': {
      transition: 'opacity 0.1s, width 0.15s, margin-left 0.15s',
      width: 0,
      opacity: 0,
      marginLeft: 0,
    },
    '&:hover .arrow': {
      opacity: 1,
      width: '1.25rem',
      marginLeft: '0.25rem',
    },
  },
};

export function formatSpeed(speed: number) {
  return `${speed < 0 ? speed.toFixed(1) : speed}x`;
}
