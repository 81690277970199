import type { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import { classes, styles } from './utils';

export interface AccordionProps extends PropsWithChildren {
  defaultExpanded?: boolean;
  label: string;
  className?: string;
  headerClassName?: string;
  labelClassName?: string;
  adornment?: ReactNode;
}

export default function Accordion({
  defaultExpanded = true,
  label,
  children,
  className = '',
  headerClassName = '',
  labelClassName = '',
  adornment,
}: AccordionProps) {
  return (
    <MuiAccordion
      square
      defaultExpanded={defaultExpanded}
      disableGutters
      sx={styles.accordion}
      className={className}>
      <AccordionSummary
        className={twMerge(classes.summary, headerClassName)}
        expandIcon={<ArrowDownIcon className={classes.expandIcon} />}>
        {adornment}
        <Typography className={twMerge(classes.summaryText, labelClassName)}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
}
