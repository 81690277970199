import { mapToKey } from '@/utils/arrays';
import { FILE_TYPES, type FileType } from '@/constants/fileTypes';
import { type DecoratedSequenceStepFile } from '@witmetrics/api-client';

export const classes = {
  wrapper: 'relative',
  sidebar: 'shrink-0 w-[300px] absolute left-0 top-0 z-10 ml-1 mt-1',
};

export function getGroupFileIDs(
  files: DecoratedSequenceStepFile[],
  fileType: FileType
) {
  return mapToKey(
    files.filter(
      (f) =>
        f.file.fileTypeID === FILE_TYPES[fileType].id ||
        (!f.file.fileTypeID && fileType === FILE_TYPES.EXTRA.name)
    ),
    'fileID'
  );
}
