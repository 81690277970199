import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddFileIcon from '@/icons/AddFileIcon';
import FileInput from '@/components/FileInput';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import { classes } from './utils';

export type OptionsMenuProps = {
  open: boolean;
  anchorTo: HTMLElement | null;
  onSelectFiles: (files: File[]) => void;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export default function OptionsMenu({
  open,
  anchorTo,
  onSelectFiles,
  onEdit,
  onDelete,
  onClose,
}: OptionsMenuProps) {
  return (
    <DefaultOptionsMenu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      onEdit={onEdit}
      onDelete={onDelete}
      onClose={onClose}
      extraOptions={
        <>
          <FileInput multiple id="new-files" onSelect={onSelectFiles} />
          <li className={classes.menuItem}>
            <label htmlFor="new-files" className={classes.inputLabel}>
              <ListItemIcon>
                <AddFileIcon />
              </ListItemIcon>
              <ListItemText>Add File(s)</ListItemText>
            </label>
          </li>
        </>
      }
    />
  );
}
