import { useRef } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Menu, { MenuItem } from '@/components/Menu';
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import { useToggle } from '@/hooks/useToggle';
import { classes, formatSpeed, type Speed, SPEEDS, styles } from './utils';

export type { Speed };

export type SpeedDropdownProps = {
  speed: Speed;
  onChange: (speed: Speed) => void;
};

export default function SpeedDropdown({ speed, onChange }: SpeedDropdownProps) {
  const buttonRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleSelect = (s: Speed) => {
    onChange(s);
    toggleMenu();
  };

  return (
    <>
      <ButtonBase
        ref={buttonRef}
        className={classes.button}
        sx={styles.button}
        onClick={() => toggleMenu()}>
        <label className={classes.label}>{formatSpeed(speed)}</label>
        <ArrowDownIcon className={classes.arrow} />
      </ButtonBase>
      {isMenuOpen && (
        <Menu
          open
          anchorTo={buttonRef.current}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => toggleMenu()}>
          {SPEEDS.map((s) => (
            <MenuItem
              key={s}
              label={formatSpeed(s)}
              onClick={() => handleSelect(s)}
            />
          ))}
        </Menu>
      )}
    </>
  );
}
