export const classes = {
  wrapper:
    'flex items-center justify-between py-3 px-4 w-full hover:bg-grey-2 rounded-lg',
  checkbox: 'mr-2 text-grey-7',
  itemWrapper: 'flex items-center justify-between grow min-w-0',
  content: 'flex items-center justify-start grow min-w-0 pl-2',
  expandWrapper:
    'flex items-center justify-center cursor-pointer transition-all',
  arrowIcon: 'text-grey-7',
  typeIcon: 'ml-1 mr-2 text-grey-6',
  label: 'text-grey-8 text-sm truncate',
  optionsIcon: 'icon-button cursor-pointer text-grey-6',
};

export const styles = {
  expanded: {
    transform: 'rotate(90deg)',
  },
  collapsed: {
    transform: 'rotate(0deg)',
  },
  itemWrapper: {
    '& .icon-button': {
      opacity: 0,
    },
    '&:hover .icon-button': {
      opacity: 1,
    },
  },
};
