'use client';
import { useRef, useState } from 'react';
import OptionsIcon from '@/icons/OptionsIcon';
import OptionsMenu from './OptionsMenu';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import Card from '@/components/Card';
import { useActiveAccount, useDispatch, useStep } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import SequenceStepDialog from '@/dialogs/SequenceStepDialog';
import DeleteSequenceStepDialog from '@/dialogs/DeleteSequenceStepDialog';
import { useAppState } from '@/providers/AppStateProvider';
import { mapToKey } from '@/utils/arrays';
import type { UploadedFile } from '@/types/files';
import { classes, styles, uploadFiles } from './utils';

export type StepTitleProps = {
  editable?: boolean;
  isLoading?: boolean;
  stepID: number;
  onSaveFiles: (uploadedFiles: UploadedFile[]) => void;
};

export default function StepTitle({
  editable,
  isLoading,
  stepID,
  onSaveFiles,
}: StepTitleProps) {
  const dispatch = useDispatch();
  const menuToggleRef = useRef(null);
  const activeAccount = useActiveAccount();
  const { onApiError, onToggleUnloadWarning } = useAppState();
  const [filesUploading, setFilesUploading] = useState<string[]>([]);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isUploadToasterOpen, toggleUploadToaster] = useToggle(false);
  const [isEditDialogShown, toggleEditDialog] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);
  const step = useStep(stepID);

  const handleEdit = () => {
    toggleMenu();
    toggleEditDialog(true);
  };

  const handleDelete = () => {
    toggleMenu();
    toggleDeleteDialog(true);
  };

  const handleSelectFiles = (files: File[]) => {
    toggleMenu();
    setFilesUploading(mapToKey(files, 'name'));
  };

  const handleUploadFiles = async (files: File[]) => {
    try {
      toggleUploadToaster(true);
      onToggleUnloadWarning(true);
      const data = await uploadFiles(files, activeAccount!.accountID);
      onSaveFiles(data);
      onToggleUnloadWarning(false);
    } catch (err) {
      toggleUploadToaster(false);
      setFilesUploading([]);
      onApiError(err, 'Error uploading files');
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={classes.title}>
          Loading
          <LoopingEllipsis />
        </div>
      );
    }
    if (!step) return null;
    return (
      <>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>
            {step.name || `Step #${step.position}`}
          </div>
          {editable && (
            <>
              <OptionsIcon
                ref={menuToggleRef}
                className={classes.optionsButton}
                onClick={() => toggleMenu()}
              />
              <OptionsMenu
                open={isMenuOpen}
                anchorTo={menuToggleRef.current}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onSelectFiles={handleSelectFiles}
                onClose={() => toggleMenu()}
              />
            </>
          )}
        </div>
        {step.description && (
          <p className={classes.description}>{step.description}</p>
        )}
      </>
    );
  };

  return (
    <>
      <Card sx={styles.card} className={classes.card}>
        {renderContent()}
      </Card>
      {isEditDialogShown && (
        <SequenceStepDialog
          stepID={stepID}
          onClose={() => toggleEditDialog(false)}
        />
      )}
      {isDeleteDialogShown && (
        <DeleteSequenceStepDialog
          stepID={stepID}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
    </>
  );
}
