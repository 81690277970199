import { uploadFile } from '@/utils/files';

export const classes = {
  card: 'm-2 py-2 px-4 flex flex-col',
  titleWrapper: 'w-full flex items-center justify-between',
  title: 'text-base md:text-lg text-grey-8 font-semibold',
  description: 'text-sm text-grey-7 mt-2',
  optionsButton: 'options-button text-grey-6 hover:text-grey-7 cursor-pointer',
};

export const styles = {
  card: {
    '& .options-button': {
      opacity: 0,
    },
    '&:hover .options-button': {
      opacity: 1,
    },
  },
};

export async function uploadFiles(files: File[], accountID: number) {
  return await Promise.all(files.map((file) => uploadFile(file, accountID)));
}
