import Card from '@/components/Card';
import List from '@mui/material/List';
import { FILE_TYPES, FileType } from '@/constants/fileTypes';
import Accordion from '@/components/Accordion';
import CheckBox from '@/components/CheckBox';
import FilesListItem from './FilesListItem';
import { useFilesByStepID } from '@/store/useStore';
import { classes, getSectionLabel, groupFilesByType } from './utils';

export type FilesListProps = {
  editable?: boolean;
  stepID: number;
  visibleFileIDs: number[];
  hiddenFileTypes: FileType[];
  onToggleFileType: (fileType: FileType, isVisible: boolean) => void;
  onToggleFile: (fileID: number) => void;
  onDeleteFile: (fileID: number) => void;
};

export default function FilesList({
  editable,
  stepID,
  visibleFileIDs,
  hiddenFileTypes,
  onToggleFileType,
  onToggleFile,
  onDeleteFile,
}: FilesListProps) {
  const files = useFilesByStepID(stepID);
  const groupedFiles = groupFilesByType(files);

  const getCheckedStatus = (fileType: FileType) => {
    const group = groupedFiles[fileType];
    const visible = group.filter((f) => visibleFileIDs.includes(f.id));
    if (hiddenFileTypes.includes(fileType)) {
      // Whole type hidden but any visible files make it indeterminate
      return visible.length > 0 ? undefined : false;
    }
    if (group.length === 0) return true; // Nothing to hide
    if (visible.length === 0) return false; // All files hidden
    if (visible.length === group.length) return true; // All files visible
    return undefined; // Some files visible -- indeterminate state
  };

  return (
    <Card className={classes.card}>
      {files.length === 0 ? (
        <div className={classes.message}>No files found.</div>
      ) : (
        <Accordion
          defaultExpanded={false}
          className={classes.accordion}
          label="Files">
          {Object.values(FILE_TYPES).map((fileType) => (
            <Accordion
              key={fileType.name}
              defaultExpanded={false}
              className={classes.accordion}
              label={getSectionLabel(fileType.name, groupedFiles)}
              adornment={
                <CheckBox
                  isChecked={getCheckedStatus(fileType.name)}
                  className={classes.checkbox}
                  name="visible"
                  onChange={(u) => onToggleFileType(fileType.name, u.visible)}
                />
              }>
              <List className={classes.list}>
                {groupedFiles[fileType.name].map((f) => (
                  <FilesListItem
                    key={f.id}
                    editable={editable}
                    fileID={f.id}
                    isOpen={visibleFileIDs.includes(f.id)}
                    onToggle={onToggleFile}
                    onDelete={onDeleteFile}
                  />
                ))}
              </List>
            </Accordion>
          ))}
        </Accordion>
      )}
    </Card>
  );
}
