import LoopingEllipsis from '@/components/LoopingEllipsis';
import ProgressBar from '@/components/ProgressBar';
import { formatPercentage } from '@/utils/strings';
import { classes } from './utils';

export type LoadingProgressProps = {
  progress: number;
};

export default function LoadingProgress({ progress }: LoadingProgressProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        Loading sequence
        <LoopingEllipsis />
      </div>
      <div className={classes.label}>{formatPercentage(progress, 0)}</div>
      <ProgressBar className={classes.progressBar} progress={100 * progress} />
    </div>
  );
}
