import { type ChangeEventHandler, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export type FileInputProps = {
  id: string;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  onSelect: (files: File[]) => void;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ id, multiple = false, disabled, className, onSelect }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.persist();
      return onSelect(e.target.files ? Array.from(e.target.files) : []);
    };

    return (
      <input
        ref={ref}
        id={id}
        multiple={multiple}
        disabled={disabled}
        className={twMerge(classes.input, className)}
        type="file"
        onChange={handleChange}
      />
    );
  }
);

export default FileInput;
