import { useEffect, useRef, useState } from 'react';
import type { UndecoratedSequenceStep } from '@witmetrics/api-client';
import { useToggle } from '@/hooks/useToggle';
import { showErrorToaster } from '@/utils/toasters';
import { sortByKey } from '@/utils/arrays';
import Slider from '@/components/Slider';
import PlayButton from './PlayButton';
import SpeedDropdown, { type Speed } from './SpeedDropdown';
import { classes } from './utils';

export type SequenceSliderProps = {
  activeStepID: number | null;
  steps: UndecoratedSequenceStep[];
  onSelect: (stepID: number) => void;
};

export default function SequenceSlider({
  activeStepID,
  steps,
  onSelect,
}: SequenceSliderProps) {
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [speed, setSpeed] = useState<Speed>(1);
  const [isPlaying, togglePlaying] = useToggle(false);

  const activeStep = steps.find((s) => s.id === activeStepID);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress);
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, [activeStepID]);

  useEffect(() => {
    if (isPlaying) {
      intervalRef.current = setInterval(() => handleIncrement(1), 800 / speed);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPlaying, speed, activeStepID]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (
      e.target &&
      ['HTMLInputElement', 'HTMLTextAreaElement'].includes(
        e.target.constructor.name
      )
    ) {
      return;
    }
    if (['ArrowRight', 'ArrowLeft'].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      handleIncrement(e.key === 'ArrowRight' ? 1 : -1);
    } else if (e.code === 'Space') {
      e.preventDefault();
      e.stopPropagation();
      togglePlaying();
    }
  };

  const handleIncrement = (inc: 1 | -1) => {
    if (!activeStep) return;
    let step: UndecoratedSequenceStep | undefined;
    const sortedSteps = sortByKey(steps, 'position', -1);
    const stepIndex = sortedSteps.findIndex((s) => s.id === activeStepID);
    if (inc === -1 && stepIndex === 0) {
      step = sortedSteps[steps.length - 1]; // Use the last step if we're at the beginning
    } else if (inc === 1 && stepIndex === steps.length - 1) {
      step = sortedSteps[0]; // Use the first step if we're at the end
    } else {
      // Try to find the next step
      step = steps.find((s) => s.position === activeStep.position + inc);
    }
    return step && onSelect(step.id);
  };

  const handleChange = (i: number) => {
    const step = steps.find((s) => s.position === i - 1); // 0-indexed
    if (!step) {
      showErrorToaster('Step not found');
      throw Error('Step not found');
    }
    return onSelect(step.id);
  };

  if (!activeStep) return null; // TODO

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.playWrapper}>
          <PlayButton isPlaying={isPlaying} onChange={() => togglePlaying()} />
          <SpeedDropdown speed={speed} onChange={(s) => setSpeed(s)} />
        </div>
        <Slider
          max={steps.length}
          value={activeStep.position + 1}
          onChange={handleChange}
        />
      </div>
    </>
  );
}
