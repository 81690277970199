import { Mesh } from 'three';
import type { UndecoratedFile } from '@witmetrics/api-client';
import { type LoadedFile } from '@/providers/LoadedFilesProvider';
import { isImage, isPdf, isVideo } from '@/utils/files';

export type LoadedMedia = {
  id: number;
  name: string;
  src: string;
};

type LoadedFiles = Record<string, LoadedFile>;

type Files = UndecoratedFile[];

export const classes = {
  wrapper: 'relative flex w-full grow overflow-hidden min-h-0',
  media: 'h-full w-full grow-0 overflow-auto min-h-0',
  tabsBlock: 'h-full w-full flex flex-col',
  tabsContent: 'w-full grow flex overflow-auto min-h-0',
  scene: 'grow bg-grey-2',
  message:
    'flex items-center justify-center w-full h-full text-xl text-grey-8 absolute top-0 left-0 z-10 backdrop-blur-lg transition-all',
  hiddenScene: 'display-none',
};

export function hasMedia(
  loadedImages: LoadedMedia[],
  loadedPdfs: LoadedMedia[],
  loadedVideos: LoadedMedia[]
) {
  return (
    loadedImages.length > 0 || loadedPdfs.length > 0 || loadedVideos.length > 0
  );
}

export function hasOnlyImage(
  loadedImages: LoadedMedia[],
  loadedPdfs: LoadedMedia[],
  loadedVideos: LoadedMedia[]
) {
  return (
    loadedImages.length === 1 &&
    loadedPdfs.length === 0 &&
    loadedVideos.length === 0
  );
}

export function hasOnlyPdf(
  loadedImages: LoadedMedia[],
  loadedPdfs: LoadedMedia[],
  loadedVideos: LoadedMedia[]
) {
  return (
    loadedImages.length === 0 &&
    loadedPdfs.length === 1 &&
    loadedVideos.length === 0
  );
}

export function hasOnlyVideo(
  loadedImages: LoadedMedia[],
  loadedPdfs: LoadedMedia[],
  loadedVideos: LoadedMedia[]
) {
  return (
    loadedImages.length === 0 &&
    loadedPdfs.length === 0 &&
    loadedVideos.length === 1
  );
}

export function getLoadedScans(
  openFileIDs: number[],
  loadedFiles: Record<string, LoadedFile>
) {
  return openFileIDs
    .map((id) => loadedFiles[id])
    .filter((file) => file instanceof Mesh) as Mesh[];
}

function buildOpenFile(fileID: number, loadedFiles: LoadedFiles, files: Files) {
  return {
    id: fileID,
    name: files.find((f) => f.id === fileID)?.name || '--',
    src: loadedFiles[fileID],
  };
}

export function getLoadedImages(
  openFileIDs: number[],
  loadedFiles: Record<string, LoadedFile>,
  files: Files
) {
  return openFileIDs
    .map((id) => buildOpenFile(id, loadedFiles, files))
    .filter(({ src, name }) => !!src && isImage(name)) as LoadedMedia[];
}

export function getLoadedPdfs(
  openFileIDs: number[],
  loadedFiles: Record<string, LoadedFile>,
  files: Files
) {
  return openFileIDs
    .map((id) => buildOpenFile(id, loadedFiles, files))
    .filter(({ src, name }) => !!src && isPdf(name)) as LoadedMedia[];
}

export function getLoadedVideos(
  openFileIDs: number[],
  loadedFiles: Record<string, LoadedFile>,
  files: Files
) {
  return openFileIDs
    .map((id) => buildOpenFile(id, loadedFiles, files))
    .filter(({ src, name }) => !!src && isVideo(name)) as LoadedMedia[];
}
